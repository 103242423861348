import RolesService from '@/service/roles/roles.js';

const state = {
  roles: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getRoles(state) {
    return state.roles;
  },
};
const actions = {
  getRoles({ commit, state }, config) {
    !config.silent && commit('resetRolesData');
    RolesService.getRoles(config).then(
      (response) => {
        state.roles.loading = false;
        commit('setRolesData', response.data);
      },
      () => {
        state.roles.loading = false;
        commit('setAppNotifications', {
          title: 'Roles Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetRolesData(state) {
    state.roles.loading = true;
    // state.roles.data = [];
    // state.roles.meta = {};
  },
  setRolesData(state, payload) {
    state.roles.data = payload.data;
    state.roles.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
