import CategoriesService from '@/service/categories/categories.js';
import { flattenTree } from '@/service/helpers.js';

const state = {
  categories: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getCategories(state) {
    return state.categories;
  },
  getFlattenCategories(state) {
    return flattenTree(state.categories.data);
  },
};
const actions = {
  getCategories({ commit, state }, config) {
    !config.silent && commit('resetCategoriesData');
    CategoriesService.getCategories(config).then(
      (response) => {
        state.categories.loading = false;
        commit('setCategoriesData', response.data);
      },
      () => {
        state.categories.loading = false;
        commit('setAppNotifications', {
          title: 'Categories Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetCategoriesData(state) {
    state.categories.loading = true;
    // state.categories.data = [];
    // state.categories.meta = {};
  },
  setCategoriesData(state, payload) {
    state.categories.data = payload.data;
    state.categories.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
