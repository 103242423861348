<template>
  <component :is="heroIcon[icon]" />
</template>

<script>
import * as heroIcons from '@heroicons/vue/solid';
import { ref } from 'vue';
export default {
  name: 'DynamicIconSolid',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  setup() {
    const heroIcon = ref(heroIcons);
    return { heroIcon };
  },
};
</script>
