import axios from 'axios';
import { errorHandling } from '@/service/helpers.js';

class ZipcodeService {
  async getZipCodes(config) {
    let url = 'panel/api/v1/zip-info/zip-codes?paginate=0';

    if (config != undefined) {
      let params = new URLSearchParams(config).toString();
      url = `${url}&${params}`;
    }
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }
}

export default new ZipcodeService();
