import { createRouter, createWebHistory } from 'vue-router';
// @ts-ignore
import Main from '../views/main/Main';
const routes = [
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'CustomersList' },
    component: Main,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/dashboard/Dashboard.vue'
            ),
        },
      },
      {
        path: 'customers',
        name: 'CustomersList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/customers/customers/List/CustomersList.vue'
            ),
        },
      },
      {
        path: 'customers/edit/:id',
        name: 'CustomersAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/customers/customers/CustomersAddEdit.vue'
            ),
        },
      },
      {
        path: 'subscribers',
        name: 'Subscribers',
        component: import(
          /* webpackChunkName: "dashboard" */ '../views/main/dashboard/Dashboard.vue'
        ),
        beforeEnter: () => {
          window.location.href = 'https://app.mailjet.com/contacts/subscribers/contact_list';
        },
      },
      {
        path: 'customers/points',
        name: 'CustomerPointsList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/customers/points/List/CustomerPointsList.vue'
            ),
        },
      },
      {
        path: 'employees',
        name: 'EmployeesList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/employees/EmployeesList.vue'
            ),
        },
      },
      {
        path: 'employees/edit/:id',
        name: 'EmployeesAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/employees/EmployeesAddEdit.vue'
            ),
        },
      },
      {
        path: 'products',
        name: 'ProductsList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/products/ProductsList.vue'
            ),
        },
      },
      {
        path: 'products/edit/:id(\\d+)',
        name: 'ProductsAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/products/ProductsAddEdit.vue'
            ),
        },
        props: true,
      },
      {
        path: 'categories',
        name: 'CategoriesList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/categories/CategoriesList.vue'
            ),
        },
      },
      {
        path: 'categories/edit/:id',
        name: 'CategoriesAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/categories/CategoriesAddEdit.vue'
            ),
        },
      },
      {
        path: 'attributes',
        name: 'AttributesList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/attributes/AttributesList.vue'
            ),
        },
      },
      {
        path: 'attributes/edit/:id',
        name: 'AttributesAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/attributes/AttributesAddEdit.vue'
            ),
        },
      },
      {
        path: 'newsletters',
        name: 'NewslettersList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/newsletters/NewslettersList.vue'
            ),
        },
      },
      {
        path: 'newsletters/edit/:id',
        name: 'NewslettersAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/newsletters/NewslettersAddEdit.vue'
            ),
        },
      },
      {
        path: 'newsletter-interests',
        name: 'NewsletterInterestsList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/newsletter-interests/NewsletterInterestsList.vue'
            ),
        },
      },
      {
        path: 'newsletter-interests/edit/:id',
        name: 'NewsletterInterestsAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/newsletter-interests/NewsletterInterestsAddEdit.vue'
            ),
        },
      },
      {
        path: 'tax-groups',
        name: 'TaxGroupsList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/tax-groups/TaxGroupsList.vue'
            ),
        },
      },
      {
        path: 'tax-groups/edit/:id',
        name: 'TaxGroupsAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/tax-groups/TaxGroupsAddEdit.vue'
            ),
        },
      },
      {
        path: 'orders',
        name: 'OrdersList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/orders/List/OrdersList.vue'
            ),
        },
      },
      {
        path: 'orders/show/:id',
        name: 'OrdersShow',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/orders/OrdersShow.vue'
            ),
        },
      },
      {
        path: 'promotions',
        name: 'PromotionsList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/promotions/PromotionsList.vue'
            ),
        },
      },
      {
        path: 'promotions/edit/:id',
        name: 'PromotionsAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "customerDetails" */ '../views/main/promotions/PromotionsAddEdit.vue'
            ),
        },
      },
      {
        path: 'depots',
        name: 'DepotList',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/depots/DepotList.vue'
            ),
        },
      },
      {
        path: 'containers',
        name: 'Containers',
        components: {
          default: Main,
          mainRouterView: () =>
            import(
              /* webpackChunkName: "dashboard" */ '../views/main/containers/list/ContainerListView.vue'
            ),
        },
      },
      {
        path: 'depots/:id',
        name: 'DepotAddEdit',
        components: {
          default: Main,
          mainRouterView: () =>
              import(
                  /* webpackChunkName: "customerDetails" */ '../views/main/depots/DepotAddEdit.vue'
                  ),
        },
      },
      {
        path: 'payments',
        name: 'PaymentsList',
        components: {
          default: Main,
          mainRouterView: () =>
              import(
                  /* webpackChunkName: "dashboard" */ '../views/main/payments/PaymentsList.vue'
                  ),
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '../views/authentications/Login.vue'
      ),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '../views/authentications/ForgotPassword.vue'
      ),
  },
  {
    path: '/auth/password-update',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '../views/authentications/ResetPassword.vue'
      ),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
