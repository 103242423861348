<template>
  <div>
    
  </div>
  <!-- MOBILE -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      static
      class="fixed inset-0 flex z-40 md:hidden"
      @close="hideMobileSidebar()"
      :open="sidebarOpen"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="
            relative
            flex-1 flex flex-col
            max-w-xs
            w-full
            pt-5
            pb-4
            bg-gray-900
          "
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                class="
                  ml-1
                  flex
                  items-center
                  justify-center
                  h-10
                  w-10
                  rounded-full
                  focus:outline-none
                  focus:ring-2
                  focus:ring-inset
                  focus:ring-white
                "
                @click="hideMobileSidebar()"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <Logo class="flex-shrink-0 flex items-center px-4" />
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <Menu class="px-2 space-y-1" />
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
  <!--  -->
  <!-- DESKTOP -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->

      <div class="flex flex-col h-0 flex-1">
        <Logo class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900" />
        <div class="w-full border-t border-gray-700"></div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <Menu class="flex-1 py-4 bg-gray-900 space-y-1" />
        </div>
      </div>
    </div>
  </div>
  <!--  -->
</template>

<script>
import { ref, watchEffect } from 'vue';
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import Menu from '@/components/@globals/layout/Menu';
import Logo from '@/components/@globals/layout/Logo';
export default {
  name: 'Sidebar',
  components: {
    Menu,
    Dialog,
    Logo,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    mobileSidebar: Boolean,
  },
  emits: ['hideMobileSidebar'],
  setup(props, context) {
    const sidebarOpen = ref(true);

    watchEffect(() => {
      sidebarOpen.value = props.mobileSidebar;
    });

    function hideMobileSidebar() {
      sidebarOpen.value = false;
      context.emit('hideMobileSidebar', sidebarOpen.value);
    }
    return { sidebarOpen, hideMobileSidebar };
  },
};
</script>
