<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <Sidebar
      :mobileSidebar="sidebarOpen"
      @hideMobileSidebar="showMobileSidebar($event)"
    />

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <Header @showMobileSidebar="showMobileSidebar($event)" />

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <Breadcrumbs :paths="breadcrumbPaths" />

        <router-view
          class="main-router mx-auto py-6 px-4 sm:px-6 md:px-8 bg-gray-100"
          name="mainRouterView"
        />
      </main>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, watchEffect } from 'vue';
import Header from '@/components/@globals/layout/Header';
import Sidebar from '@/components/@globals/layout/Sidebar';
import Breadcrumbs from '@/components/@globals/layout/Breadcrumbs';
import { useRoute } from 'vue-router';
export default {
  name: 'Main',
  components: {
    Header,
    Sidebar,
    Breadcrumbs,
  },

  setup() {
    const route = useRoute();
    const sidebarOpen = ref(false);
    const breadcrumbPaths = ref([]);

    function showMobileSidebar(payload) {
      sidebarOpen.value = payload;
    }
    watchEffect(() => {
      prepareBreadcrumbPaths(route.matched);
      sidebarOpen.value = false;
    });
    function prepareBreadcrumbPaths(routes) {
      breadcrumbPaths.value = [];
      routes.map((route) => {
        if (route.path != '/') breadcrumbPaths.value.push({ name: route.name });
      });
    }
    return {
      sidebarOpen,
      breadcrumbPaths,
      showMobileSidebar,
    };
  },

  // Main Route Guard
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem('access_token')) {
      next();
    } else {
      next({ name: 'Login' });
    }
  },
};
</script>
