<template>
  <div>
    <img class="h-12 w-auto" src="@/assets/Logo_jelonek.svg" alt="jelonek" />
    <h6 class="text-gray-600 text-sm mt-2 ml-4">Backoffice 0.0.1</h6>
  </div>
</template>

<script>
export default {
  name: 'Logo',
};
</script>
