<template>
  <div
    class="
      max-w-sm
      w-full
      bg-white
      shadow-lg
      rounded-lg
      pointer-events-auto
      ring-1 ring-black ring-opacity-5
      overflow-hidden
    "
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <DynamicIconSolid
            :icon="icon.name"
            :class="icon.color"
            class="h-6 w-6"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">
            {{ title }}
          </p>
          <p v-if="content" class="mt-1 text-sm text-gray-500">
            {{ content }}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            @click="close()"
            class="
              bg-white
              rounded-md
              inline-flex
              text-gray-400
              hover:text-gray-500
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            <span class="sr-only">Close</span>

            <DynamicIconSolid icon="XIcon" aria-hidden="true" class="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'Notification',
  props: {
    id: {
      type: Number,
    },
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    type: {
      type: String,
      validator: (type) =>
        ['success', 'warning', 'error', 'info'].includes(type),
    },
    autoclose: {
      type: Boolean,
    },
  },
  setup(props) {
    const icon = ref({
      name: '',
      color: '',
    });
    const store = useStore();
    switch (props.type) {
      case 'success':
        icon.value.name = 'CheckCircleIcon';
        icon.value.color = 'text-green-400';
        break;
      case 'warning':
        icon.value.name = 'ExclamationCircleIcon';
        icon.value.color = 'text-yellow-400';
        break;
      case 'info':
        icon.value.name = 'InformationCircleIcon';
        icon.value.color = 'text-blue-400';
        break;
      case 'error':
        icon.value.name = 'XCircleIcon';
        icon.value.color = 'text-red-400';
        break;
      default:
        icon.value.name = 'CheckCircleIcon';
        icon.value.color = 'text-green-400';
    }

    onMounted(() => {
      props.autoclose && store.dispatch('clearAppNotifications', props.id);
    });
    function close() {
      store.commit('removeAppNotifications', props.id);
    }
    return {
      icon,
      close,
    };
  },
};
</script>
