const state = {
  notifications: [],
};
const getters = {
  getAppNotifications(state) {
    return state.notifications;
  },
};
const actions = {
  clearAppNotifications({ commit }, payload) {
    setTimeout(function () {
      commit('removeAppNotifications', payload);
    }, 1500);
  },
};
const mutations = {
  setAppNotifications(state, payload) {
    let uId = 0;
    if (state.notifications.length == 0) {
      uId = state.notifications.length;
      payload.id = uId;
    } else {
      uId = maxBy(state.notifications, (item) => item.id).id + 1;
      payload.id = uId;
    }
    state.notifications.splice(0, 0, payload);
  },
  removeAppNotifications(state, id) {
    const index = state.notifications.findIndex((item) => item.id == id);
    state.notifications.splice(index, 1);
  },
  clearAllAppNotifications(state) {
    state.notifications = [];
  },
};

// Helpers

const maxBy = (arr, func) => {
  const max = Math.max(...arr.map(func));
  return arr.find((item) => func(item) === max);
};

export default {
  state,
  getters,
  actions,
  mutations,
};
