import TaxRatesService from '@/service/tax-rates/taxRates.js';

const state = {
  taxRates: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getTaxRates(state) {
    return state.taxRates;
  },
};
const actions = {
  getTaxRates({ commit, state }, config) {
    !config.silent && commit('resetTaxRatesData');
    TaxRatesService.getTaxRates(config).then(
      (response) => {
        state.taxRates.loading = false;
        commit('setTaxRatesData', response.data);
      },
      () => {
        state.taxRates.loading = false;
        commit('setAppNotifications', {
          title: 'Tax Rates Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetTaxRatesData(state) {
    state.taxRates.loading = true;
    // state.taxRates.data = [];
    // state.taxRates.meta = {};
  },
  setTaxRatesData(state, payload) {
    state.taxRates.data = payload.data;
    state.taxRates.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
