import PackTypesService from '@/service/pack-types/packTypes.js';

const state = {
  packTypes: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getPackTypes(state) {
    return state.packTypes;
  },
};
const actions = {
  getPackTypes({ commit, state }, config) {
    !config.silent && commit('resetPackTypesData');
    PackTypesService.getPackTypes(config).then(
      (response) => {
        state.packTypes.loading = false;
        commit('setPackTypesData', response.data);
      },
      () => {
        state.packTypes.loading = false;
        commit('setAppNotifications', {
          title: 'PackTypes Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetPackTypesData(state) {
    state.packTypes.loading = true;
    // state.packTypes.data = [];
    // state.packTypes.meta = {};
  },
  setPackTypesData(state, payload) {
    state.packTypes.data = payload.data;
    state.packTypes.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
