import DepotsService from '@/service/depots/depots.js';

const state = {
  depots: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getDepots(state) {
    return state.depots;
  },
};
const actions = {
  getDepots({ commit, state }, config) {
    !config.silent && commit('resetDepotsData');
    DepotsService.getDepots(config).then(
      (response) => {
        state.depots.loading = false;
        commit('setDepotsData', response.data);
      },
      () => {
        state.depots.loading = false;
        commit('setAppNotifications', {
          title: 'Depot Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetDepotsData(state) {
    state.depots.loading = true;
  },
  setDepotsData(state, payload) {
    state.depots.data = payload.data;
    state.depots.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
