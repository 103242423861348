import OrdersService from '@/service/orders/orders.js';

const state = {
  orders: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getOrders(state) {
    return state.orders;
  },
};
const actions = {
  getOrders({ commit, state }, config) {
    !config.silent && commit('resetOrdersData');
    OrdersService.getOrders(config).then(
      (response) => {
        state.orders.loading = false;
        commit('setOrdersData', response.data);
      },
      () => {
        state.orders.loading = false;
        commit('setAppNotifications', {
          title: 'Orders Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetOrdersData(state) {
    state.orders.loading = true;
    // state.employees.data = [];
    // state.employees.meta = {};
  },
  setOrdersData(state, payload) {
    state.orders.data = payload.data;
    state.orders.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
