import axios from 'axios';
import { errorHandling } from '@/service/helpers.js';

class AuthService {
  async login(user) {
    try {
      const response = await axios.post('oauth/token', user);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  async refreshToken(token_data) {
    try {
      const response = await axios.post('oauth/token', token_data);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  logout() {
    localStorage.removeItem('user');
  }

  async forgotPassword(form) {
    let url = `panel/api/v1/auth/forgot-password`;
    try {
      const response = await form.post(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  async resetPassword(form) {
    let url = `panel/api/v1/auth/reset-password`;
    try {
      const response = await form.post(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }
}

export default new AuthService();
