import axios from "axios";
import { errorHandling } from "@/service/helpers.js";

class PaymentsService {
  async getPayments(config) {
    let url = "panel/api/v1/payment-intents?paginate=1";

    if (config !== undefined) {
      let params = new URLSearchParams(config).toString();
      url = `${url}&${params}`;
    }
    try {
      return await axios.get(url);
    } catch (error) {
      return errorHandling(error);
    }
  }
}

export default new PaymentsService();
