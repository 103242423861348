import PromotionsService from '@/service/promotions/promotions.js';

const state = {
  promotions: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getPromotions(state) {
    return state.promotions;
  },
};
const actions = {
  getPromotions({ commit, state }, config) {
    !config.silent && commit('resetPromotionsData');
    PromotionsService.getPromotions(config).then(
      (response) => {
        state.promotions.loading = false;
        commit('setPromotionsData', response.data);
      },
      () => {
        state.promotions.loading = false;
        commit('setAppNotifications', {
          title: 'Promotions Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetPromotionsData(state) {
    state.promotions.loading = true;
    // state.promotions.data = [];
    // state.promotions.meta = {};
  },
  setPromotionsData(state, payload) {
    state.promotions.data = payload.data;
    state.promotions.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
