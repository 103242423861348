import TaxGroupsService from '@/service/tax-groups/taxGroups.js';

const state = {
  taxGroups: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getTaxGroups(state) {
    return state.taxGroups;
  },
};
const actions = {
  getTaxGroups({ commit, state }, config) {
    !config.silent && commit('resetTaxGroupsData');
    TaxGroupsService.getTaxGroups(config).then(
      (response) => {
        state.taxGroups.loading = false;
        commit('setTaxGroupsData', response.data);
      },
      () => {
        state.taxGroups.loading = false;
        commit('setAppNotifications', {
          title: 'Tax Groups Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetTaxGroupsData(state) {
    state.taxGroups.loading = true;
    // state.taxGroups.data = [];
    // state.taxGroups.meta = {};
  },
  setTaxGroupsData(state, payload) {
    state.taxGroups.data = payload.data;
    state.taxGroups.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
