import router from '../../router';
import AuthService from '@/service/oauth/oauth.service.js';

const state = {
  logged: false,
};
const getters = {
  isLogged(state) {
    return state.logged;
  },
};
const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('username', user.email);
      formData.append('password', user.password);
      formData.append('grant_type', 'password');
      formData.append('client_id', process.env.VUE_APP_CLIENT_ID);
      formData.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);

      AuthService.login(formData).then(
        (response) => {
          commit('userLogged', true);
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          resolve();
          router.push({
            name: 'Main',
          });
          commit('setAppNotifications', {
            title: 'Zalogowano pomyślnie',
            content: '',
            type: 'info',
            autoclose: true,
          });
        },
        () => {
          commit('setAppNotifications', {
            title: 'Problem z logowaniem',
            content: '',
            type: 'error',
            autoclose: true,
          });
          reject();
        }
      );
    });
  },

  refreshToken({ commit }) {
    return new Promise((resolve) => {
      const formData = new FormData();

      formData.append('refresh_token', localStorage.getItem('refresh_token'));
      formData.append('grant_type', 'refresh_token');
      formData.append('client_id', process.env.VUE_APP_CLIENT_ID);
      formData.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      AuthService.refreshToken(formData).then(
        (response) => {
          console.log(response);
          resolve();
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
        },
        () => {
          commit('setAppNotifications', {
            title: 'Token wygasł - wylogowano',
            content: 'Brak interakcji uzytkownika',
            type: 'warning',
            autoclose: true,
          });
        }
      );
    });
  },
  logout({ commit }) {
    commit('userLogged', false);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    router.push({
      name: 'Login',
    });
    commit('setAppNotifications', {
      title: 'Wylogowano pomyślnie',
      content: '',
      type: 'info',
      autoclose: true,
    });
  },
};

const mutations = {
  userLogged(state, payload) {
    payload ? (state.logged = true) : (state.logged = false);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
