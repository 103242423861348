import ProductIngredientsService from '@/service/product-ingredients/productIngredients.js';

const state = {
  productIngredients: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getProductIngredients(state) {
    return state.productIngredients;
  },
};
const actions = {
  getProductIngredients({ commit, state }, config) {
    !config.silent && commit('resetProductIngredientsData');
    ProductIngredientsService.getProductIngredients(config).then(
      (response) => {
        state.productIngredients.loading = false;
        commit('setProductIngredientsData', response.data);
      },
      () => {
        state.productIngredients.loading = false;
        commit('setAppNotifications', {
          title: 'Product Ingredients Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetProductIngredientsData(state) {
    state.productIngredients.loading = true;
    // state.productIngredients.data = [];
    // state.productIngredients.meta = {};
  },
  setProductIngredientsData(state, payload) {
    state.productIngredients.data = payload.data;
    state.productIngredients.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
