import PaymentsService from "@/service/payments/payments.js";

const state = {
  payments: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getPayments(state) {
    return state.payments;
  },
};
const actions = {
  getPayments({ commit, state }, config) {
    !config.silent && commit("resetPaymentsData");
    PaymentsService.getPayments(config).then(
      (response) => {
        state.payments.loading = false;
        commit("setPaymentsData", response.data);
      },
      () => {
        state.payments.loading = false;
        commit("setAppNotifications", {
          title: "Payment Error",
          content: "",
          type: "error",
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetPaymentsData(state) {
    state.payments.loading = true;
  },
  setPaymentsData(state, payload) {
    state.payments.data = payload.data;
    state.payments.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
