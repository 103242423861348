import RecipeIngredientsService from '@/service/recipe-ingredients/recipeIngredients.js';

const state = {
  recipeIngredients: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getRecipeIngredients(state) {
    return state.recipeIngredients;
  },
};
const actions = {
  getRecipeIngredients({ commit, state }, config) {
    !config.silent && commit('resetRecipeIngredientsData');
    RecipeIngredientsService.getRecipeIngredients(config).then(
      (response) => {
        state.recipeIngredients.loading = false;
        commit('setRecipeIngredientsData', response.data);
      },
      () => {
        state.recipeIngredients.loading = false;
        commit('setAppNotifications', {
          title: 'Recipe Ingredients Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetRecipeIngredientsData(state) {
    state.recipeIngredients.loading = true;
    // state.recipeIngredients.data = [];
    // state.recipeIngredients.meta = {};
  },
  setRecipeIngredientsData(state, payload) {
    state.recipeIngredients.data = payload.data;
    state.recipeIngredients.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
