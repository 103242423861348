export function errorHandling(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log('DATA: ', error.response.data);
    console.log('STATUS: ', error.response.status);
    console.log('HEADERS: ', error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('REQUEST ', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  return Promise.reject(error);
}

export function flattenTree(node, result = [], depth = 0) {
  if (depth === 0 && node instanceof Array) {
    for (const child of node) flattenTree(child, result, depth);
  } else {
    result.push(Object.assign({ depth: depth }, node, { children: undefined }));
    for (const child of node.children) flattenTree(child, result, depth + 1);
  }

  return result;
}
