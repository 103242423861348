import UnitsService from '@/service/units/units.js';

const state = {
  units: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getUnits(state) {
    return state.units;
  },
};
const actions = {
  getUnits({ commit, state }, config) {
    !config.silent && commit('resetUnitsData');
    UnitsService.getUnits(config).then(
      (response) => {
        state.units.loading = false;
        commit('setUnitsData', response.data);
      },
      () => {
        state.units.loading = false;
        commit('setAppNotifications', {
          title: 'Units Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetUnitsData(state) {
    state.units.loading = true;
    // state.units.data = [];
    // state.units.meta = {};
  },
  setUnitsData(state, payload) {
    state.units.data = payload.data;
    state.units.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
