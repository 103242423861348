import ZipCodeService from '@/service/zip-code/zipCode.js';

const state = {
  zipCodes: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getZipCodes(state) {
    return state.zipCodes;
  },
};
const actions = {
  getZipCodes({ commit, state }, config) {
    !config.silent && commit('resetZipCodesData');
    ZipCodeService.getZipCodes(config).then(
      (response) => {
        state.zipCodes.loading = false;
        commit('setZipCodesData', response.data);
      },
      () => {
        state.zipCodes.loading = false;
        commit('setAppNotifications', {
          title: 'Zip codes Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetZipCodesData(state) {
    state.zipCodes.loading = true;
  },
  setZipCodesData(state, payload) {
    state.zipCodes.data = payload.data;
    state.zipCodes.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
