import NewslettersService from '@/service/newsletters/newsletters.js';

const state = {
  newsletters: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getNewsletters(state) {
    return state.newsletters;
  },
};
const actions = {
  getNewsletters({ commit, state }, config) {
    !config.silent && commit('resetNewslettersData');
    NewslettersService.getNewsletters(config).then(
      (response) => {
        state.newsletters.loading = false;
        commit('setNewslettersData', response.data);
      },
      () => {
        state.newsletters.loading = false;
        commit('setAppNotifications', {
          title: 'Newsletters Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetNewslettersData(state) {
    state.newsletters.loading = true;
    // state.newsletters.data = [];
    // state.newsletters.meta = {};
  },
  setNewslettersData(state, payload) {
    state.newsletters.data = payload.data;
    state.newsletters.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
