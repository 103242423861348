<template>
  <section class="flex items-center">
    <div
      v-if="iconName"
      class="
        bg-blue-100
        w-16
        h-16
        mr-4
        rounded-full
        flex
        justify-center
        items-center
      "
    >
      <DynamicIconSolid :icon="iconName" class="text-blue-900 h-7" />
    </div>
    <div>
      <h1 class="font-normal text-4xl">{{ title }}</h1>
      <p class="font-light text-sm text-gray-400" v-if="subTitle">
        {{ subTitle }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionHeading',
  props: {
    title: String,
    subTitle: String,
    iconName: String,
  },
};
</script>
