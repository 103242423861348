import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from 'heroiconsV2/24/outline';
import { defineStore } from 'pinia';

export interface Notification {
  icon: any,
  type: 'success' | 'error' | 'warning' | 'info';
  title: string,
  message?: string,
  duration?: number,
}

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [] as Notification[],
  }),
  getters: {
    getDefaultNotificationDuration: () => (title: string, message?: string): number => {
      const titleLength = title.length;
      const messageLength = message ? message.length : 0;
      const totalLength = titleLength + messageLength;
      let duration = totalLength * 50;

      if (duration < 1500) {
        duration = 1500;
      }

      if (duration > 10000) {
        duration = 10000;
      }

      return duration;
    },
  },
  actions: {
    addError(title: string, message?: string, duration?: number) {
      this.notifications.push({
        icon: ExclamationCircleIcon,
        type: 'error',
        title,
        message,
        duration: duration !== undefined ? duration : this.getDefaultNotificationDuration(title, message),
      });
    },
    addWarning(title: string, message?: string, duration?: number) {
      this.notifications.push({
        icon: ExclamationTriangleIcon,
        type: 'warning',
        title,
        message,
        duration: duration !== undefined ? duration : this.getDefaultNotificationDuration(title, message),
      });
    },
    addSuccess(title: string, message?: string, duration?: number) {
      this.notifications.push({
        icon: CheckCircleIcon,
        type: 'success',
        title,
        message,
        duration: duration !== undefined ? duration : this.getDefaultNotificationDuration(title, message),
      });
    },
    addInfo(title: string, message?: string, duration?: number) {
      this.notifications.push({
        icon: InformationCircleIcon,
        type: 'info',
        title,
        message,
        duration: duration !== undefined ? duration : this.getDefaultNotificationDuration(title, message),
      });
    },
  },
});
