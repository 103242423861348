import EmployeesService from '@/service/employees/employees.js';

const state = {
  employees: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getEmployees(state) {
    return state.employees;
  },
};
const actions = {
  getEmployees({ commit, state }, config) {
    !config.silent && commit('resetEmployeesData');
    EmployeesService.getEmployees(config).then(
      (response) => {
        state.employees.loading = false;
        commit('setEmployeesData', response.data);
      },
      () => {
        state.employees.loading = false;
        commit('setAppNotifications', {
          title: 'Employees Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetEmployeesData(state) {
    state.employees.loading = true;
    // state.employees.data = [];
    // state.employees.meta = {};
  },
  setEmployeesData(state, payload) {
    state.employees.data = payload.data;
    state.employees.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
