import { createStore } from 'vuex';
import authentication from './authentication/authentication';
import notifications from './notifications/notifications';
import customers from './customers/customers';
import employees from './employees/employees';
import orders from './orders/orders';
import roles from './roles/roles';
import newsletters from './newsletters/newsletters';
import newsletterInterests from './newsletter-interests/newsletterInterests';
import categories from './categories/categories';
import attributes from './attributes/attributes';
import taxGroups from './tax-groups/taxGroups';
import taxRates from './tax-rates/taxRates';
import products from './products/products';
import units from './units/units';
import packTypes from './pack-types/packTypes';
import recipeIngredients from './recipe-ingredients/recipeIngredients';
import productIngredients from './product-ingredients/productIngredients';
import promotions from './promotions/promotions';
import depots from './depots/depots';
import zipCodes from './zip-codes/zipCodes';
import payments from './payments/payments';

export default createStore({
  modules: {
    authentication,
    notifications,
    customers,
    employees,
    roles,
    newsletters,
    newsletterInterests,
    categories,
    attributes,
    taxRates,
    taxGroups,
    products,
    units,
    packTypes,
    recipeIngredients,
    productIngredients,
    orders,
    promotions,
    depots,
    zipCodes,
    payments,
  },
});
