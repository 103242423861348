import axios from 'axios';
import { errorHandling } from '@/service/helpers.js';
import store from '@/store/index.js';
class TaxGroupsService {
  async getTaxGroups(config) {
    let url = 'panel/api/v1/tax-groups';

    try {
      const response = await axios.get(url, {
        params: config,
      });
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  async getTaxGroupDetails(id) {
    let url = `panel/api/v1/tax-groups/${id}`;
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  async updateTaxGroup(id, form) {
    let url = `panel/api/v1/tax-groups/${id}`;
    try {
      const response = await form.put(url);
      store.commit('setAppNotifications', {
        title: 'Uzytkownik zaktualizowano',
        content: 'Pomyslnie zaktualizowano grupę podatkową',
        type: 'success',
        autoclose: true,
      });
      return response;
    } catch (error) {
      store.commit('setAppNotifications', {
        title: 'Bląd podczas aktualizacji',
        content: 'Blad podczas aktualziacji',
        type: 'error',
        autoclose: true,
      });
      return errorHandling(error);
    }
  }

  async addTaxGroup(form) {
    let url = `panel/api/v1/tax-groups`;
    try {
      const response = await form.post(url);
      store.commit('setAppNotifications', {
        title: 'Uzytkownik dodany',
        content: 'Pomyślnie dodano grupę podatkową',
        type: 'success',
        autoclose: true,
      });
      return response;
    } catch (error) {
      store.commit('setAppNotifications', {
        title: 'Bląd podczas dodawania',
        content: 'Blad podczas dodawania',
        type: 'error',
        autoclose: true,
      });
      return errorHandling(error);
    }
  }

  async deleteTaxGroup(id) {
    let url = `panel/api/v1/tax-groups/${id}`;
    try {
      const response = await axios.delete(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }
}

export default new TaxGroupsService();
