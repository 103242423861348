import NewsletterInterestsService from '@/service/newsletter-interests/newsletterInterests.js';

const state = {
  newsletterInterests: {
    loading: false,
    data: [],
    meta: {},
  },
};
const getters = {
  getNewsletterInterests(state) {
    return state.newsletterInterests;
  },
};
const actions = {
  getNewsletterInterests({ commit, state }, config) {
    !config.silent && commit('resetNewsletterInterestsData');
    NewsletterInterestsService.getNewsletterInterests(config).then(
      (response) => {
        state.newsletterInterests.loading = false;
        commit('setNewsletterInterestsData', response.data);
      },
      () => {
        state.newsletterInterests.loading = false;
        commit('setAppNotifications', {
          title: 'NewsletterInterests Error', // TODO: errors name + translate
          content: '',
          type: 'error',
          autoclose: true,
        });
      }
    );
  },
};
const mutations = {
  resetNewsletterInterestsData(state) {
    state.newsletterInterests.loading = true;
    // state.newsletterInterests.data = [];
    // state.newsletterInterests.meta = {};
  },
  setNewsletterInterestsData(state, payload) {
    state.newsletterInterests.data = payload.data;
    state.newsletterInterests.meta = payload.meta;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
