import { createApp } from 'vue';
import App from '@/App.vue';
// @ts-ignore
import router from '@/router';
// @ts-ignore
import store from './store';
import './assets/tailwind.css';
// @ts-ignore
import { restriction, closable } from './directives/directives';
// @ts-ignore
import setupAxios from './service/api.config';
// @ts-ignore
import i18n from './i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import * as PL from 'dayjs/locale/pl';
import * as EN from 'dayjs/locale/en';
import 'v-calendar/dist/style.css';

// @ts-ignore
import { SetupCalendar, DatePicker } from 'v-calendar';
import { variantJS } from '@variantjs/vue';
import SectionHeading from '@/components/@globals/layout/SectionHeading.vue';
import DynamicIconSolid from '@/components/@globals/helpers/DynamicIconSolid.vue';
import { createPinia } from 'pinia';

localStorage.setItem('app_lang', 'pl');

const lang = 'pl';
// localStorage.getItem('app_lang') != undefined
//   ? localStorage.getItem('app_lang')
//   : 'en';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale(lang == 'pl' ? PL : EN);

// AXIOS SETUP
setupAxios();
const pinia = createPinia();
const app = createApp(App);
//PINIA
app.use(pinia);
// CALENDER
app.use(SetupCalendar, {});
app.component('DatePicker', DatePicker);
// DATE
app.config.globalProperties.$dayjs = dayjs;
// GLOBAL DIRECTIVE
app.directive('closable', closable);
app.directive('restriction', restriction); // [WIP]
// GLOBAL COMPONENTS
app.component('SectionHeading', SectionHeading);
app.component('DynamicIconSolid', DynamicIconSolid);
// VARIANTJS
app.use(variantJS, {});
// MOUNT APP
app.use(store).use(i18n).use(router).mount('#app');
