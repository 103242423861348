import axios from 'axios';
import router from '@/router';
import store from '@/store';

function axiosGlobal() {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
}
function axiosRequest() {
  axios.interceptors.request.use(
    (config) => {
      const access = localStorage.getItem('access_token');
      const newConfig = config;
      if (access) {
        newConfig.headers.Authorization = `Bearer ${access}`;
      }
      return newConfig;
    },
    (err) => Promise.reject(err)
  );
}

function axiosResponse() {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        originalRequest.url.includes('oauth/token')
      ) {
        router.push({
          name: 'Login',
        });
      }
      if (
        localStorage.getItem('refresh_token') &&
        error.response.status === 401
      ) {
        await store.dispatch('refreshToken');
        const access = localStorage.getItem('access_token');
        axios.defaults.headers.common.Authorization = `Bearer ${access}`;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}

export default function setupAxios() {
  axiosGlobal();
  axiosRequest();
  axiosResponse();
}
